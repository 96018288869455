import { appearAnimation, useIsomorphicEffect, useMatchBreakpoints, Text, Link, Flex } from '@pancakeswap/uikit'
import { useWeb3React } from '@pancakeswap/wagmi'
import { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from '@pancakeswap/localization'
import SwiperCore, { Autoplay, EffectFade, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useMultipleBannerConfig } from './hooks/useMultipleBannerConfig'

const StyledSwiper = styled(Swiper)`
  padding: 20px;
  position: relative;
  overflow: visible;
  opacity: 0;
  background-color: #756b50;
  border-radius: 46px;
  width: 80%;
  animation: ${appearAnimation} 0.3s ease-in-out 0.7s forwards;
  .swiper-pagination {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    width: 108px;
    bottom: 12px;
    ${({ theme }) => theme.mediaQueries.sm} {
      bottom: 35px;
    }
    ${({ theme }) => theme.mediaQueries.md} {
      bottom: 45px;
    }
    ${({ theme }) => theme.mediaQueries.lg} {
      bottom: 35px;
    }
  }
  .swiper-pagination-bullet {
    background-color: white;
    flex-basis: 108px;
    margin: 0 !important;
    border-radius: 0px;
    &:first-child {
      border-radius: 4px 0px 0px 4px;
    }
    &:last-child {
      border-radius: 0px 4px 4px 0px;
    }
  }
  img {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
  .title {
    color: #f5b200;
    margin-bottom: 8px;
    font-size: 24px;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`

const BURNCONFIG = [
  'https://bscscan.com/tx/0xe8187562340cb6230870d095135f9fded2a6e9f3d7c189b7140e16f0e1f5e0f5',
  'https://bscscan.com/tx/0x9c1a3adb1fbc0103be9d9899dffb328b0bf0419e4a0467e610b7c828d646321d',
  'https://bscscan.com/tx/0x1326dabe6c3df99cd7c84cf24bd95198cd892d3eefa3b7147c111e3c5f3b1643',
  'https://bscscan.com/tx/0x1326dabe6c3df99cd7c84cf24bd95198cd892d3eefa3b7147c111e3c5f3b1643',
  'https://bscscan.com/tx/0x3141f2bfc6c4e96f8696b00fa2e0aee26c19c395cf605bfc54c1d01d8999e934',
  'https://bscscan.com/tx/0x0f656fe0f886d0a101f0661af73865e63c9731a991bb9f219ec250233fefa49a',
  'https://bscscan.com/tx/0x0500bf2dd50d5e120c5afa431e19bbff927199e1057bf7b68bd1a1f51b3245c6',
  'https://bscscan.com/tx/0x202a3ceee5418fef5ac36684ec2ce893d939da6333e6087775fd5beca1b50faa',
  'https://bscscan.com/tx/0xc8f4e7e261550c8c2e054dd97204f244e2cf719796491d442dfe8fb4ed82c2f9',
  'https://bscscan.com/tx/0x6adcea950a6ed708cfd67ea6ab849c96f9f71374ff43e344959d681a7c37d751',
  'https://bscscan.com/tx/0x88319b9b857a354924e062f086e36376f527c4eb5ffe678566882751af6fe0cb',
  'https://bscscan.com/tx/0xdf287a4b33769de23bf00abcd9b096aadb2cd3ef9faa95c17429b031b1ea524f',
  'https://bscscan.com/tx/0x82ccfc9a0be26e22e48b9ae7eb491eac704ec912e9819cc9f332c457782397bb',
  'https://bscscan.com/tx/0x6546b6a61a8bda21c85729b094657337ac88f4361b9a8e5cd320b41275ea370c',
  'https://bscscan.com/tx/0x4bcf1d147708d70d2cd81829402efe44b419dd4e446199563ad3e5c6ed4d95f1',
  'https://bscscan.com/tx/0x2537fb98a2a30189fbb18b5aeba1c918032f594220fc1556a7afb9132f065def',
  'https://bscscan.com/tx/0x46bfbc9341c2a20395b0c160b4c8d3b66d5739dd62c80c4f2e2355a2f8e95f07',
  'https://bscscan.com/tx/0x6638397d5a12394af88ce69a55f29716af5b4161dda15af7bc55bae5244e12b3',
  'https://bscscan.com/tx/0x8537cc7186d5f97aa5cdb1373b9d251a0d74ae746ff8a840cff97ce8c2bf260c',
  'https://bscscan.com/tx/0x2738dbe2a4c3749fc76780f9e005a738aacf1f32d5c091c3af4cac5d47182d88',
]

const DonationConfig = [
  {
    label: 'Donation 1 (Ukraine donation wallet, for relief efforts.)',
    href: 'https://bscscan.com/tx/0x22b650eeb68639a71383cfb19aee9c268b7dd5d9cb7e8232776600359c0daf43',
  },
  {
    label: 'Donation 2 (Unchain Fund Donation Wallet)',
    href: 'https://bscscan.com/tx/0xc4ec053a1f14fad8a754285605ffd60b9142eb4e201ebe3309cee9beea918858',
  },
  {
    label: 'Donation 3 (@BinanceBCF Turkey&Syria earthquake)',
    href: 'https://bscscan.com/tx/0x7e240c78ba26d2ecc8d0d7f60adb814246fe219f040efc8b2e94ffb3fd8baab3',
  },
  {
    label: 'Donation 4',
    href: 'https://bscscan.com/tx/0x492a996c9e0b1974ddc9402b0ffd9bb6c867677d09338caf1310bd1fc8b2a6ca',
  },
  {
    label: 'Donation 5',
    href: 'https://bscscan.com/tx/0x8537cc7186d5f97aa5cdb1373b9d251a0d74ae746ff8a840cff97ce8c2bf260c',
  },
]

const MultipleBanner: React.FC<React.PropsWithChildren> = () => {
  const bannerList = useMultipleBannerConfig()
  const { account } = useWeb3React()
  const { isDesktop, isTablet } = useMatchBreakpoints()
  const [swiperRef, setSwiperRef] = useState<SwiperCore>(null)
  const { t } = useTranslation()

  useIsomorphicEffect(() => {
    if (swiperRef) {
      if (bannerList.length > 1 && !swiperRef.autoplay?.running) {
        swiperRef.autoplay?.start()
      } else if (bannerList.length <= 1 && swiperRef.autoplay?.running) {
        swiperRef.autoplay?.stop()
      }
    }
  }, [bannerList, swiperRef])

  return (
    <>
      <StyledSwiper
        onSwiper={setSwiperRef}
        modules={[Autoplay, Pagination, EffectFade]}
        spaceBetween={50}
        observer
        slidesPerView={1}
        effect="fade"
        fadeEffect={{ crossFade: true }}
        speed={500}
        autoplay={{ delay: 5000, pauseOnMouseEnter: true, disableOnInteraction: false }}
        loop
        pagination={{ clickable: true }}
      >
        <SwiperSlide style={{ padding: isDesktop || isTablet ? 20 : 0 }}>
          <Text bold className="title">
            {t('Burn')}
          </Text>
          <Flex flexWrap="wrap">
            {BURNCONFIG.map((item, index) => (
              <Link href={item} target="_blank" style={{ margin: '1rem' }} color="#280d5f">
                {t('Burn')} {index + 1}
              </Link>
            ))}
          </Flex>
        </SwiperSlide>
        <SwiperSlide style={{ padding: isDesktop || isTablet ? 20 : 0 }}>
          <Text bold className="title">
            {t('Donations')}
          </Text>
          {DonationConfig.map((item, index) => (
            <Link href={item.href} target="_blank" style={{ margin: '1rem' }} color="#280d5f">
              {item.label}
            </Link>
          ))}
        </SwiperSlide>
        {/* <SwiperSlide style={{ padding: isDesktop || isTablet ? 20 : 0 }}>
          <Text bold className="title">
            (Q4 2022) Launch - 0%
          </Text>
          <Text color="#fff">
            <img src="/images/tiger/icon.png" alt="" />
            1,000 Holders
          </Text>
          <Text color="#fff">
            <img src="/images/tiger/icon.png" alt="" />
            Website Launch
          </Text>
          <Text color="#fff">
            <img src="/images/tiger/icon.png" alt="" />
            2,000 Telegram Members
          </Text>
          <Text color="#fff">
            <img src="/images/tiger/icon.png" alt="" />
            Marketing Campaign
          </Text>
          <Text color="#fff">
            <img src="/images/tiger/icon.png" alt="" />
            3,000 Holders
          </Text>
          <Text color="#fff">
            <img src="/images/tiger/icon.png" alt="" />
            CoinMarketCap Listing
          </Text>
        </SwiperSlide>
        <SwiperSlide style={{ padding: isDesktop || isTablet ? 20 : 0 }}>
          <Text bold className="title">
            (Q4 2023) Launch - 25%
          </Text>
          <Text color="#fff">
            <img src="/images/tiger/icon.png" alt="" />
            5,000 Telegram Members
          </Text>
          <Text color="#fff">
            <img src="/images/tiger/icon.png" alt="" />
            Website Redesign
          </Text>
          <Text color="#fff">
            <img src="/images/tiger/icon.png" alt="" />
            Burn Plan/Events
          </Text>
          <Text color="#fff">
            <img src="/images/tiger/icon.png" alt="" />
            9,000 Holders
          </Text>
          <Text color="#fff">
            <img src="/images/tiger/icon.png" alt="" />
            10,000 Telegram Members
          </Text>
          <Text color="#fff">
            <img src="/images/tiger/icon.png" alt="" />
            18,000 Holders
          </Text>
        </SwiperSlide>
        <SwiperSlide style={{ padding: isDesktop || isTablet ? 20 : 0 }}>
          <Text bold className="title">
            (Q2-3 2023) Expansion - 50%
          </Text>
          <Text color="#fff">
            <img src="/images/tiger/icon.png" alt="" />
            BNBTiger SWAP(Decentralize Exchange)
          </Text>
          <Text color="#fff">
            <img src="/images/tiger/icon.png" alt="" />
            Initial CEX Listings (Hotbit, Bilaxy, CoinTiger)
          </Text>
          <Text color="#fff">
            <img src="/images/tiger/icon.png" alt="" />
            15,000 Telegram Members
          </Text>
          <Text color="#fff">
            <img src="/images/tiger/icon.png" alt="" />
            30,000 Holders
          </Text>
        </SwiperSlide>
        <SwiperSlide style={{ padding: isDesktop || isTablet ? 20 : 0 }}>
          <Text bold className="title">
            (Q4 2023 And Beyond) Utility -75%
          </Text>
          <Text color="#fff">
            <img src="/images/tiger/icon.png" alt="" />
            BNBTiger Burrow (NFT Maketplace)
          </Text>
          <Text color="#fff">
            <img src="/images/tiger/icon.png" alt="" />
            BNBTiger Trail of Wonders (Wwallet and Passive Income Tracker)
          </Text>
          <Text color="#fff">
            <img src="/images/tiger/icon.png" alt="" />
            BNBTiger in WONDERLAND(Merchandise Store)
          </Text>
          <Text color="#fff">
            <img src="/images/tiger/icon.png" alt="" />3 More CEX Listings
          </Text>
          <Text color="#fff">
            <img src="/images/tiger/icon.png" alt="" />
            50,000 Telegran Members
          </Text>
          <Text color="#fff">
            <img src="/images/tiger/icon.png" alt="" />
            90,000 Holders
          </Text>
          <Text color="#fff">
            <img src="/images/tiger/icon.png" alt="" />
            Influencer Marketing Partnership
          </Text>
        </SwiperSlide> */}
      </StyledSwiper>
    </>
  )
}

export default MultipleBanner
