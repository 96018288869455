import styled from 'styled-components'
import PageSection from 'components/PageSection'
import { useWeb3React } from '@pancakeswap/wagmi'
import useTheme from 'hooks/useTheme'
import Container from 'components/Layout/Container'
import { PageMeta } from 'components/Layout/Page'
import { useTranslation } from '@pancakeswap/localization'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { Button, Flex, Text } from '@pancakeswap/uikit'
import { ChainId } from '@pancakeswap/sdk'
import Hero from './components/Hero'
import { swapSectionData, earnSectionData, cakeSectionData } from './components/SalesSection/data'
import MetricsSection from './components/MetricsSection'
import SalesSection from './components/SalesSection'
import WinSection from './components/WinSection'
import FarmsPoolsRow from './components/FarmsPoolsRow'
import Footer from './components/Footer'
import CakeDataRow from './components/CakeDataRow'
import { WedgeTopLeft, InnerWedgeWrapper, OuterWedgeWrapper, WedgeTopRight } from './components/WedgeSvgs'
import UserBanner from './components/UserBanner'
import MultipleBanner from './components/Banners/MultipleBanner'
import MultipleBannerA from './components/Banners/MultipleBannerA'

const StyledHeroSection = styled(PageSection)`
  padding-top: 16px;
  position: relative;

  ${({ theme }) => theme.mediaQueries.md} {
    padding-top: 48px;
  }
  .nft1 {
    right: 10%;
    top: 30%;
  }
  .nft2 {
    top: 3%;
    right: 45%;
  }
  .nft {
    color: red;
    font-size: 30px;
    position: absolute;
    text-shadow: 4px 2px #c4c41a;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
  }
`

const HomeLogo = styled.div`
  .hometltleLogo {
    width: 100%;
  }

  @media (max-width: 768px) {
    .homeTitle {
      flex-direction: column;
    }
  }
`

const Home: React.FC<React.PropsWithChildren> = () => {
  const { theme } = useTheme()
  const { account } = useWeb3React()
  const { chainId } = useActiveChainId()

  const HomeSectionContainerStyles = { margin: '0', width: '100%', maxWidth: '968px' }

  const { t } = useTranslation()

  return (
    <>
      <PageMeta />
      <StyledHeroSection
        innerProps={{ style: { margin: '0', width: '100%' } }}
        background={
          theme.isDark
            ? 'radial-gradient(103.12% 50% at 50% 50%, #21193A 0%, #191326 100%)'
            : 'linear-gradient(180deg,#fff 22%,#efe0b1 100%);'
        }
        index={2}
        hasCurvedDivider={false}
      >
        {/* <MultipleBanner /> */}
        <HomeLogo>
          <Flex className="homeTitle" justifyContent="center" alignItems="center">
            <div className="hometltleLogo">
              <img src="/images/tiger/logo.png" alt="" width="100%" />
            </div>
            <div>
              <Text color="#f5b200" bold fontSize="30px">
                BNBTiger!
              </Text>
              <Text color="#f5b200" bold fontSize="20px">
                Fierce.Firm.Victory
              </Text>
              <Text>
                {t(
                  `BNBTiger is the king of beasts. They are the symbol of victory and strength. Powerful and tough.and our $BNBTIGER community is the same.`,
                )}
              </Text>
              <Text>
                {t(
                  '$BNBTIGER is a decentralized experiment, launched on the January 3, 2022, by the founder,  BNBTiger9527(telegram,X), fresh opportunity for those who were struggling in the midst of the biggest  bear market since. Born out of trials and tribulation, the $BNBTIGER community is the strongest community youll find, supportive of one another, and pushing each other towards success.',
                )}
              </Text>
            </div>
          </Flex>
        </HomeLogo>
        {/* <div className="nft nft1">
          <span>NFT</span>
        </div>
        <div className="nft nft2">
          <span>NFT</span>
        </div> */}
      </StyledHeroSection>

      <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        background={theme.colors.background}
        index={2}
        hasCurvedDivider={false}
        style={{ background: 'linear-gradient(180deg,#b2a24826 0%,#4aadd770 100%)' }}
      >
        <Button mb="1rem">{t('More Info')}</Button>
        {/* <SalesSection {...cakeSectionData(t)} /> */}
        <CakeDataRow />
      </PageSection>

      <StyledHeroSection
        innerProps={{ style: { margin: '0', width: '100%' } }}
        background={
          theme.isDark
            ? 'radial-gradient(103.12% 50% at 50% 50%, #21193A 0%, #191326 100%)'
            : 'linear-gradient(180deg,#fff 22%,#efe0b1 100%);'
        }
        index={2}
        hasCurvedDivider={false}
      >
        <MultipleBannerA />
      </StyledHeroSection>
    </>
  )
}

export default Home
